const FooterXIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_1821)">
  <path d="M13.5857 10.9998L20.0821 3.44824H18.5426L12.9018 10.0051L8.39652 3.44824H3.2002L10.0131 13.3634L3.2002 21.2823H4.73972L10.6966 14.358L15.4545 21.2823H20.6508L13.5853 10.9998H13.5857ZM11.4771 13.4508L10.7868 12.4634L5.29443 4.60717H7.65904L12.0915 10.9474L12.7817 11.9348L18.5434 20.1761H16.1787L11.4771 13.4511V13.4508Z" fill="#F6F6F6"/>
  </g>
  <defs>
  <clipPath id="clip0_1_1821">
  <rect width="17.4506" height="17.8432" fill="white" transform="translate(3.2002 3.44824)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default FooterXIcon;
          