const FooterBybitIcon = () => (
  <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.700195" y="0.948242" width="39" height="29" rx="2.5" stroke="#F6F6F6" stroke-opacity="0.2"/>
  <g clip-path="url(#clip0_1_1763)">
  <path d="M25.5186 17.8488V11.4243H26.8099V17.8488H25.5186Z" fill="#F7A600"/>
  <path d="M10.4686 19.7575H7.7002V13.333H10.3573C11.6486 13.333 12.4011 14.0368 12.4011 15.1377C12.4011 15.8503 11.9178 16.3108 11.5834 16.4642C11.9826 16.6445 12.4937 17.0505 12.4937 17.9081C12.4937 19.1078 11.6486 19.7575 10.4686 19.7575ZM10.2551 14.4521H8.99155V15.9319H10.2551C10.8031 15.9319 11.1097 15.6341 11.1097 15.1917C11.1097 14.7499 10.8031 14.4521 10.2551 14.4521ZM10.3386 17.0598H8.99155V18.6389H10.3386C10.924 18.6389 11.2023 18.2782 11.2023 17.8447C11.2023 17.4117 10.9235 17.0598 10.3386 17.0598Z" fill="#F6F6F6"/>
  <path d="M16.4325 17.1227V19.7575H15.1502V17.1227L13.1621 13.333H14.5648L15.8005 15.9225L17.0175 13.333H18.4201L16.4325 17.1227Z" fill="#F6F6F6"/>
  <path d="M22.0809 19.7575H19.3125V13.333H21.9696C23.2609 13.333 24.0134 14.0368 24.0134 15.1377C24.0134 15.8503 23.5301 16.3108 23.1957 16.4642C23.5949 16.6445 24.106 17.0505 24.106 17.9081C24.106 19.1078 23.2609 19.7575 22.0809 19.7575ZM21.8674 14.4521H20.6039V15.9319H21.8674C22.4154 15.9319 22.722 15.6341 22.722 15.1917C22.722 14.7499 22.4154 14.4521 21.8674 14.4521ZM21.9509 17.0598H20.6039V18.6389H21.9509C22.5363 18.6389 22.8146 18.2782 22.8146 17.8447C22.8146 17.4117 22.5363 17.0598 21.9509 17.0598Z" fill="#F6F6F6"/>
  <path d="M30.972 14.4521V19.758H29.6807V14.4521H27.9526V13.333H32.7001V14.4521H30.972Z" fill="#F6F6F6"/>
  </g>
  <defs>
  <clipPath id="clip0_1_1763">
  <rect width="25" height="9.77011" fill="white" transform="translate(7.7002 10.563)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default FooterBybitIcon;
          