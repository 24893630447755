import CloseIcon from '@mui/icons-material/Close';
import { MouseEvent, useState } from "react";
import { useTheme } from 'styled-components';

import ByBitLogoSmall from "src/assets/home-page/bybit-logo-small";
import useConfig from "src/hooks/swr/useConfig";
import useMatchMedia from 'src/hooks/useMatchMedia';

import { ByBitHeaderWrapper, CloseIconWrapper } from './styles';

export const ByBitHeader = () => {
  const [isBannerClosed, setIsBannerClosed] = useState(localStorage.getItem('isByBitBannerClosed') === 'true');
  const { data: config } = useConfig();
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));


  const handleCloseBanner = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setIsBannerClosed(true);
    localStorage.setItem('isByBitBannerClosed', 'true');
  }

  const handleRedirect = () => {
    window.open('https://entravel.com/bybit', '_blank');
  }

  if (isBannerClosed || !config?.featureToggles.ShowByBit || isTablet) {
    return null
  }

  return (
    <ByBitHeaderWrapper onClick={handleRedirect}>
      NEWS: Bybit, a global market leader with 60m users - selects Entravel as official hotel partner
      <ByBitLogoSmall />

      <CloseIconWrapper onClick={handleCloseBanner}>
        <CloseIcon />
      </CloseIconWrapper>
    </ByBitHeaderWrapper>
  )
}