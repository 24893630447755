import { VFC, MouseEvent } from 'react';
import styled from 'styled-components/macro';

import { DataElementsId } from 'src/constants';
import { media } from 'src/modules/mediaQuery';
import { I18n } from 'src/components/I18n';
import { CustomButton } from 'src/components/shared/CustomButton';
import { Phone, TabletDesktop } from 'src/components/shared/CResponsive';

const StyledCustomButton = styled(CustomButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #19191A;
  border-radius: 150px !important;
  padding: 12px 24px;

  &:hover {
    background: #19191A;
  }

  &:active {
    background: #19191A;
  }

  &:focus {
    background: #19191A;
  }

  ${(p) => media.phone`
    border-radius: 5px;
    padding: 9px 12px;

    &:hover,
  `}

  ${media.tabletDesktop`
    top: 65%;
  `}
`;

const MapIcon = styled.span.attrs({ className: 'icon-map' })`
  font-size: 20px;
  color: ${(p) => p.theme.custom.whiteMainColor};
`;

interface HotelPreviewMapButtonProps {
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const HotelPreviewMapButton: VFC<HotelPreviewMapButtonProps> = ({
  className,
  onClick,
}) => (
  <StyledCustomButton
    className={className}
    btnType="primary"
    onClick={onClick}
    data-test-id={DataElementsId.MAP_HOTEL_DETAIL_BUTTON}
  >
    <TabletDesktop>
      <I18n id="GENERAL_SHOW_MAP" />
    </TabletDesktop>
    <Phone>
      <MapIcon />
    </Phone>
  </StyledCustomButton>
);
