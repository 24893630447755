import Stack, { StackProps } from '@mui/material/Stack';
import { FC, ComponentType, MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { LinkProps } from '@mui/material/Link';

import getText, { TextProps } from 'src/modules/getText';

import { Link } from './Link';

export type StackLink = {
  url: string;
  Icon?: ComponentType<{}> | false;
} & TextProps;

export type LinksStackProps = {
  links: StackLink[];
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
} & StackProps &
  Pick<LinkProps, 'typography'>;

const IconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing(3)};
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-weight: 400;
`

const LinksStack: FC<LinksStackProps> = ({
  links,
  children,
  onLinkClick,
  typography,
  ...props
}) => (
  <Stack {...props}>
    {links.map(({ url, Icon, ...props }, index) => (
      <StyledLink
        to={url}
        key={index}
        display="flex"
        alignItems="center"
        onClick={onLinkClick}
        typography={typography}
      >
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        {getText(props)}
      </StyledLink>
    ))}
    {children}
  </Stack>
);

export default LinksStack;
