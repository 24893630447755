import { useTheme } from "styled-components";
import AppleIcon from '@mui/icons-material/Apple';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FooterBybitIcon from "src/assets/home-page/footer-bybit-icon";
import FooterMastercardIcon from "src/assets/home-page/footer-mastercard-icon";
import FooterUsdcIcon from "src/assets/home-page/footer-usdc-icon";
import FooterUsdtIcon from "src/assets/home-page/footer-usdt-icon";
import FooterVisaIcon from "src/assets/home-page/footer-visa-icon";
import LockIcon from "src/assets/home-page/lock-icon";
import Logo from "src/components/common/Logo";
import GooglePlayIcon from 'src/assets/icons/playstore.svg';
import FooterXIcon from "src/assets/home-page/footer-x-icon";
import FooterTelegramIcon from "src/assets/home-page/footer-telegram-icon";
import FooterDiscordIcon from "src/assets/home-page/footer-discord-icon";
import useConfig from "src/hooks/swr/useConfig";
import useMatchMedia from "src/hooks/useMatchMedia";
import { userSelector } from "src/store/user/selectors";
import { useOpenAuthModal } from "src/components/Auth/AuthModalProvider";
import { EAuth } from "src/components/Auth";

import { 
  AppButton,
  AppButtonInfo,
  AppButtonStoreTitle,
  AppButtonTitle,
  FooterAppActions,
  FooterAppLinksSection,
  FooterAppMobileContainer,
  FooterContainer,
  FooterLinks,
  FooterLinksNetworkItems,
  FooterLinksSection,
  FooterLinksSectionItem,
  FooterLinksSectionItems,
  FooterLinksSectionTitle,
  FooterMobileLinks, 
  FooterMobileLinksContainer, 
  FooterRights, 
  FooterTopSection, 
  FooterTopSectionPayments,
  FooterTopSectionPaymentsList,
  FooterTopSectionPaymentsTitle,
  FooterTopSectionTitle,
  FooterWrapper,

} from "./styles";


const iOSLink = 'https://apps.apple.com/us/app/entravel/id6502831135';
const androidLink = 'https://play.google.com/store/apps/details?id=com.entravel.android';

export const Footer = () => {
  const { data: config } = useConfig();
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));
  const { isAuthenticated } = useSelector(userSelector);
  const handleOpenModal = useOpenAuthModal();
  const history = useHistory();

  const handleOpenAndroidApp = () => {
    window.open(androidLink);
  }

  const handleOpenIOSApp = () => {
    window.open(iOSLink);
  }

  const handleOpenBookingsPage = () => {
    if (!isAuthenticated) {
      handleOpenModal(EAuth.SIGN_IN);

      return;
    }

    history.push('/account/bookings')
  }

  const renderLinks = () => {
    if (isTablet) {
      return (
        <FooterMobileLinks>
          <FooterTopSectionPayments>
            <FooterTopSectionPaymentsTitle>
              <LockIcon />
              Secure payment
            </FooterTopSectionPaymentsTitle>
            <FooterTopSectionPaymentsList>
              <FooterUsdtIcon />
              <FooterUsdcIcon />
              <FooterVisaIcon />
              <FooterMastercardIcon />
              {config?.featureToggles.ShowByBit ? <FooterBybitIcon /> : null}
            </FooterTopSectionPaymentsList>
          </FooterTopSectionPayments>

          <FooterMobileLinksContainer>
            <FooterLinksSection>
              <FooterLinksSectionTitle>
                Company
              </FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterLinksSectionItem href="https://entravel.com/about" target="_blank">
                  About us
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/impressum" target="_blank">
                  Corporate Contact
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/contact" target="_blank">
                  Contact us
                </FooterLinksSectionItem>
              </FooterLinksSectionItems>
            </FooterLinksSection>

            <FooterLinksSection>
              <FooterLinksSectionTitle>
                Support
              </FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterLinksSectionItem onClick={handleOpenBookingsPage}>
                  Manage your trips
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/contact" target="_blank">
                  Contact customer service
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/faq" target="_blank">
                  Help Center
                </FooterLinksSectionItem>
              </FooterLinksSectionItems>
            </FooterLinksSection>

            <FooterLinksSection>
              <FooterLinksSectionTitle>
                Legal
              </FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterLinksSectionItem href="https://entravel.com/privacy-policy" target="_blank">
                  Privacy & Cookies
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/terms-and-conditions" target="_blank">
                  Terms and Conditions
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://entravel.com/terms-and-conditions" target="_blank">
                  Dispute resolution
                </FooterLinksSectionItem>
              </FooterLinksSectionItems>
            </FooterLinksSection>
          </FooterMobileLinksContainer>

          <FooterAppLinksSection>
            <FooterAppMobileContainer>
              <FooterLinksSectionTitle>
                Get the app
              </FooterLinksSectionTitle>
              <FooterLinksSectionItems>
                <FooterAppActions>
                  <AppButton onClick={handleOpenAndroidApp}>
                    <img src={GooglePlayIcon} alt="google-play-button" />
                    <AppButtonInfo>
                      <AppButtonTitle>
                        Get in on
                      </AppButtonTitle>
                      <AppButtonStoreTitle>
                        Google Play
                      </AppButtonStoreTitle>
                    </AppButtonInfo>
                  </AppButton>

                  <AppButton onClick={handleOpenIOSApp}>
                    <AppleIcon />
                    <AppButtonInfo>
                      <AppButtonTitle>
                        Download on the
                      </AppButtonTitle>
                      <AppButtonStoreTitle>
                        App Store
                      </AppButtonStoreTitle>
                    </AppButtonInfo>
                  </AppButton>
                </FooterAppActions>
              </FooterLinksSectionItems>
            </FooterAppMobileContainer>

            <FooterAppMobileContainer>
              <FooterLinksSectionTitle>
                Follow us
              </FooterLinksSectionTitle>
              <FooterLinksNetworkItems>
                <FooterLinksSectionItem href="https://twitter.com/entravelhotels" target="_blank">
                  <FooterXIcon />
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://t.me/entravel_official" target="_blank">
                  <FooterTelegramIcon />
                </FooterLinksSectionItem>
                <FooterLinksSectionItem href="https://discord.com/invite/nHTjZ9sVAu" target="_blank">
                  <FooterDiscordIcon />
                </FooterLinksSectionItem>
              </FooterLinksNetworkItems>
            </FooterAppMobileContainer>
          </FooterAppLinksSection>
        </FooterMobileLinks>
      )
    }

    return (
      <FooterLinks>
        <FooterLinksSection>
          <FooterLinksSectionTitle>
            Company
          </FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterLinksSectionItem href="https://entravel.com/about" target="_blank">
              About us
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/impressum" target="_blank">
              Corporate Contact
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/contact" target="_blank">
              Contact us
            </FooterLinksSectionItem>
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          <FooterLinksSectionTitle>
            Support
          </FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterLinksSectionItem onClick={handleOpenBookingsPage}>
              Manage your trips
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/contact" target="_blank">
              Contact customer service
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/faq" target="_blank">
              Help Center
            </FooterLinksSectionItem>
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          <FooterLinksSectionTitle>
            Legal
          </FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterLinksSectionItem href="https://entravel.com/privacy-policy" target="_blank">
              Privacy & Cookies
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/terms-and-conditions" target="_blank">
              Terms and Conditions
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://entravel.com/terms-and-conditions" target="_blank">
              Dispute resolution
            </FooterLinksSectionItem>
          </FooterLinksSectionItems>
        </FooterLinksSection>

        <FooterLinksSection>
          <FooterLinksSectionTitle>
            Get the app
          </FooterLinksSectionTitle>
          <FooterLinksSectionItems>
            <FooterAppActions>
              <AppButton onClick={handleOpenAndroidApp}>
                <img src={GooglePlayIcon} alt="google-play-button" />
                <AppButtonInfo>
                  <AppButtonTitle>
                    Get in on
                  </AppButtonTitle>
                  <AppButtonStoreTitle>
                    Google Play
                  </AppButtonStoreTitle>
                </AppButtonInfo>
              </AppButton>

              <AppButton onClick={handleOpenIOSApp}>
                <AppleIcon />
                <AppButtonInfo>
                  <AppButtonTitle>
                    Download on the
                  </AppButtonTitle>
                  <AppButtonStoreTitle>
                    App Store
                  </AppButtonStoreTitle>
                </AppButtonInfo>
              </AppButton>
            </FooterAppActions>
          </FooterLinksSectionItems>

          <FooterLinksSectionTitle>
            Follow us
          </FooterLinksSectionTitle>
          <FooterLinksNetworkItems>
            <FooterLinksSectionItem href="https://twitter.com/entravelhotels" target="_blank">
              <FooterXIcon />
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://t.me/entravel_official" target="_blank">
              <FooterTelegramIcon />
            </FooterLinksSectionItem>
            <FooterLinksSectionItem href="https://discord.com/invite/nHTjZ9sVAu" target="_blank">
              <FooterDiscordIcon />
            </FooterLinksSectionItem>
          </FooterLinksNetworkItems>
        </FooterLinksSection>
      </FooterLinks>
    )
  }

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterTopSection>
          <FooterTopSectionTitle>
            <Logo />
            A truly revolutionary hotel booking concept
          </FooterTopSectionTitle>

          {!isTablet ? (
            <FooterTopSectionPayments>
              <FooterTopSectionPaymentsTitle>
                <LockIcon />
                Secure payment
              </FooterTopSectionPaymentsTitle>
              <FooterTopSectionPaymentsList>
                <FooterUsdtIcon />
                <FooterUsdcIcon />
                <FooterVisaIcon />
                <FooterMastercardIcon />
                {config?.featureToggles.ShowByBit ? <FooterBybitIcon /> : null}
              </FooterTopSectionPaymentsList>
            </FooterTopSectionPayments>
          ) : null}
        </FooterTopSection>
        
        {renderLinks()}

        <FooterRights>
          © 2025 Entravel ApS. All rights reserved
        </FooterRights>
      </FooterWrapper>
    </FooterContainer>
  )
}
