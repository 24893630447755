const ByBitLogoSmall = () => (
  <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="0.72168" width="32" height="18.7701" rx="2.5" stroke="white"/>
  <g clip-path="url(#clip0_305_814)">
  <path d="M22.3184 11.5077V5.08325H23.6097V11.5077H22.3184Z" fill="#F7A600"/>
  <path d="M7.26841 13.4159H4.5V6.99146H7.15709C8.44845 6.99146 9.20089 7.69525 9.20089 8.79614C9.20089 9.50875 8.71764 9.96927 8.38316 10.1226C8.78241 10.303 9.29348 10.7089 9.29348 11.5665C9.29348 12.7662 8.44845 13.4159 7.26841 13.4159ZM7.05487 8.11053H5.79135V9.59033H7.05487C7.6029 9.59033 7.90954 9.29252 7.90954 8.85019C7.90954 8.40835 7.6029 8.11053 7.05487 8.11053ZM7.13836 10.7183H5.79135V12.2973H7.13836C7.72382 12.2973 8.00213 11.9366 8.00213 11.5031C8.00213 11.0702 7.7233 10.7183 7.13836 10.7183Z" fill="white"/>
  <path d="M13.2323 10.7812V13.4159H11.95V10.7812L9.96191 6.99146H11.3646L12.6003 9.58099L13.8173 6.99146H15.2199L13.2323 10.7812Z" fill="white"/>
  <path d="M18.8807 13.4159H16.1123V6.99146H18.7694C20.0608 6.99146 20.8132 7.69525 20.8132 8.79614C20.8132 9.50875 20.3299 9.96927 19.9955 10.1226C20.3947 10.303 20.9058 10.7089 20.9058 11.5665C20.9058 12.7662 20.0608 13.4159 18.8807 13.4159ZM18.6672 8.11053H17.4037V9.59033H18.6672C19.2152 9.59033 19.5218 9.29252 19.5218 8.85019C19.5218 8.40835 19.2152 8.11053 18.6672 8.11053ZM18.7507 10.7183H17.4037V12.2973H18.7507C19.3361 12.2973 19.6144 11.9366 19.6144 11.5031C19.6144 11.0702 19.3361 10.7183 18.7507 10.7183Z" fill="white"/>
  <path d="M27.7718 8.11053V13.4164H26.4805V8.11053H24.7524V6.99146H29.4999V8.11053H27.7718Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_305_814">
  <rect width="25" height="9.77011" fill="white" transform="translate(4.5 4.22168)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default ByBitLogoSmall;
          