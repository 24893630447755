const FooterUsdcIcon = () => (
  <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.700195" y="0.948242" width="39" height="29" rx="2.5" stroke="#F6F6F6" stroke-opacity="0.2"/>
  <g clip-path="url(#clip0_1_1753)">
  <path d="M20.2002 24.4482C25.1877 24.4482 29.2002 20.4358 29.2002 15.4482C29.2002 10.4607 25.1877 6.44824 20.2002 6.44824C15.2127 6.44824 11.2002 10.4607 11.2002 15.4482C11.2002 20.4358 15.2127 24.4482 20.2002 24.4482Z" fill="#2775CA"/>
  <path d="M22.6754 16.8734C22.6754 15.561 21.8879 15.111 20.3129 14.9235C19.1879 14.7735 18.9629 14.4735 18.9629 13.9484C18.9629 13.4234 19.3379 13.086 20.0879 13.086C20.7629 13.086 21.1379 13.311 21.3254 13.8735C21.3629 13.986 21.4754 14.0609 21.5879 14.0609H22.1878C22.3379 14.0609 22.4504 13.9484 22.4504 13.7985V13.761C22.3003 12.9359 21.6253 12.2985 20.7629 12.2235V11.3235C20.7629 11.1735 20.6504 11.061 20.4629 11.0234H19.9004C19.7504 11.0234 19.6379 11.1359 19.6003 11.3235V12.186C18.4753 12.336 17.7629 13.086 17.7629 14.0235C17.7629 15.261 18.5129 15.7484 20.0879 15.936C21.1379 16.1235 21.4754 16.3485 21.4754 16.9485C21.4754 17.5485 20.9503 17.961 20.2379 17.961C19.2628 17.961 18.9253 17.5484 18.8128 16.9859C18.7754 16.836 18.6629 16.7609 18.5504 16.7609H17.9128C17.7629 16.7609 17.6504 16.8734 17.6504 17.0235V17.061C17.8003 17.9984 18.4004 18.6734 19.6379 18.861V19.761C19.6379 19.9109 19.7504 20.0234 19.9378 20.061H20.5003C20.6504 20.061 20.7629 19.9485 20.8004 19.761V18.861C21.9254 18.6734 22.6754 17.8859 22.6754 16.8734Z" fill="#F6F6F6"/>
  <path d="M18.2874 20.8107C15.3624 19.7607 13.8624 16.4982 14.95 13.6107C15.5125 12.0357 16.75 10.8357 18.2874 10.2732C18.4375 10.1982 18.5124 10.0857 18.5124 9.89818V9.37321C18.5124 9.22318 18.4375 9.11068 18.2874 9.07324C18.2499 9.07324 18.1749 9.07324 18.1374 9.11068C14.575 10.2357 12.6249 14.0232 13.7499 17.5857C14.4249 19.6857 16.0374 21.2982 18.1374 21.9732C18.2874 22.0482 18.4375 21.9732 18.4749 21.8232C18.5124 21.7857 18.5124 21.7482 18.5124 21.6732V21.1482C18.5124 21.0357 18.3999 20.8857 18.2874 20.8107ZM22.2625 9.11068C22.1124 9.03571 21.9624 9.11068 21.925 9.26071C21.8875 9.29824 21.8874 9.33568 21.8874 9.41074V9.93571C21.8874 10.0857 21.9999 10.2357 22.1124 10.3107C25.0374 11.3607 26.5375 14.6232 25.4499 17.5107C24.8874 19.0857 23.6499 20.2857 22.1124 20.8482C21.9624 20.9232 21.8874 21.0357 21.8874 21.2232V21.7482C21.8874 21.8982 21.9624 22.0107 22.1124 22.0482C22.15 22.0482 22.2249 22.0482 22.2625 22.0107C25.8249 20.8857 27.775 17.0982 26.65 13.5357C25.975 11.3982 24.3249 9.78568 22.2625 9.11068Z" fill="#F6F6F6"/>
  </g>
  <defs>
  <clipPath id="clip0_1_1753">
  <rect width="18" height="18" fill="white" transform="matrix(1 0 0 -1 11.2002 24.4482)"/>
  </clipPath>
  </defs>
  </svg>
)
          
export default FooterUsdcIcon;
          