import Modal, { ModalProps } from '@mui/material/Modal';
import Slide, { SlideProps } from '@mui/material/Slide';
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  RefCallback,
  useCallback,
} from 'react';
import styled, { CSSObject, useTheme } from 'styled-components';
import 'styled-components/macro';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import CloseIcon from "@mui/icons-material/Close";

import mediaVariables from 'src/modules/mediaVariables';
import useMatchMedia from 'src/hooks/useMatchMedia';

import { IconButton, ButtonWrapper } from '../WrappedButtons';

const StyledHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledWrapper = styled.div(
  mediaVariables(
    {
      xs: { padding: ({ theme }) => theme.spacing(8) },
      md: { padding: ({ theme }) => theme.spacing(6) },
    },
    ({ padding }, { theme }) => ({
      padding,
      [StyledHeaderWrapper]: { paddingBottom: padding },
      [theme.breakpoints.down('sm')]: { width: '100%' },
      background: theme.palette.background.default,
      minWidth: '375px',
      overflow: 'auto',
    })
  )
);

const StyledHeader = styled(Typography)`
  margin: auto;
`;

const StyledButtonWrapper = styled.div`
  width: 0;
  ${ButtonWrapper} {
    width: fit-content;
    transform: translateX(-25%);
  }
`;

export type CDrawerProps = PropsWithChildren<
  Omit<ModalProps, 'sx' | 'ref' | 'children' | 'onClose'> & {
    anchor?: EAnchor;
    header?: ReactNode;
    SlideProps?: Omit<SlideProps, 'in' | 'direction' | 'ref' | 'children'>;
    onClose: () => void;
    withCrossIcon?: boolean;
  }
>;

export enum EAnchor {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
}

const getDirection = (
  anchor: EAnchor
): Required<
  Pick<SlideProps, 'direction'> & Pick<CSSObject, 'flexDirection'>
> => {
  switch (anchor) {
    case EAnchor.LEFT:
      return { direction: 'right', flexDirection: 'row' };
    case EAnchor.RIGHT:
      return { direction: 'left', flexDirection: 'row-reverse' };
    case EAnchor.TOP:
      return { direction: 'down', flexDirection: 'column' };
    case EAnchor.BOTTOM:
      return { direction: 'up', flexDirection: 'column-reverse' };
  }
};

const CDrawer = forwardRef<HTMLDivElement, CDrawerProps>(
  (
    {
      children,
      open,
      className,
      header,
      anchor = EAnchor.RIGHT,
      SlideProps,
      withCrossIcon,
      ...props
    },
    ref
  ) => {
    const { direction, flexDirection } = getDirection(anchor);

    const theme = useTheme();

    const isMobile = useMatchMedia(theme.breakpoints.down('sm'));

    const wrapperRef = useCallback<RefCallback<HTMLDivElement>>((el) => {
      if (el) {
        disablePageScroll(el);
      } else {
        enablePageScroll();
      }
    }, []);

    return (
      <Modal
        open={open}
        {...props}
        css={`
          display: flex;
          flex-direction: ${flexDirection};
        `}
        disableScrollLock
        disableEnforceFocus
        hideBackdrop={isMobile}
      >
        <Slide in={open} direction={direction} {...SlideProps}>
          <StyledWrapper className={className} ref={wrapperRef}>
            <StyledContainer ref={ref}>
              <StyledHeaderWrapper>
                <StyledButtonWrapper>
                  <IconButton onClick={props.onClose}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </StyledButtonWrapper>
                {header && <StyledHeader variant="h5">{header}</StyledHeader>}
                {withCrossIcon ? (
                  <IconButton onClick={props.onClose}>
                  <CloseIcon fontSize="large" />
                </IconButton>
                ) : null }
              </StyledHeaderWrapper>
              
              {children}
            </StyledContainer>
          </StyledWrapper>
        </Slide>
      </Modal>
    );
  }
);

export default CDrawer;
