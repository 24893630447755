import styled from "styled-components"

export const ByBitHeaderWrapper = styled.div`
  height: 36px;
  width: 100%;
  background: rgba(33, 33, 33, 1);
  color: #fff;
  position: relative;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: sticky;
  top: 0;
  z-index: 100000;
  cursor: pointer;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 6px;
`
