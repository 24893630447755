import { FC } from 'react';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';
import { I18n } from 'src/components/I18n';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';

import { FacilitiesList } from './FacilitiesList';

const Wrapper = styled.ul`
  width: 100%;
  transform: translateZ(0);
  margin: 0;
  columns: 3;

  ${media.extraSmall`
    columns: 2;
  `}
`;

const NoteText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.custom.grayTextColor};

  a {
    text-decoration: underline;
  }
`;

interface FacilitiesProps {
  data: NonNullable<HotelInfoSelectorResult>['facilityGroups'];
}

export const Facilities: FC<FacilitiesProps> = ({ data }) => (
  <>
    <Wrapper>
      <FacilitiesList items={data} />
    </Wrapper>
    <NoteText>
      <I18n
        id="HOTEL_DETAILS_PAGE.FACILITIES.CUSTOMER_NOTE"
        values={{
          contactmaillink: (...chunks: any) => (
            <a href="mailto:support@entravel.com">{chunks}</a>
          ),
        }}
        tagName="span"
      />
    </NoteText>
  </>
);
