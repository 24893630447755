import LoadingButton from "@mui/lab/LoadingButton";
import styled, { useTheme } from "styled-components";

import useMatchMedia from "src/hooks/useMatchMedia";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: clamp(600px, calc(600px + (100vw - 1200px) / 2), 960px);
  width: 100%;
  margin: auto;
`;

export const FormContainer = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    max-width: 446px;
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;

    padding: ${isMobile ? '80px 24px' : '36px 0'};
  `
});

export const FormTitle = styled.h2`
  font-family: Roboto, sans-serif;
  /* font-weight: 600; */
  font-size: 32px;
  text-align: center;
  line-height: 36px;
`

export const CvexHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CvexTitle = styled.h2`
  font-family: Roboto, sans-serif;
  /* font-weight: 600; */
  font-size: 24px;
  text-align: center;
  line-height: 36px;
`

export const CvexDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #323232;
`

export const Image = styled.img`
  max-width: 220px;
  margin-bottom: 16px;
`;

export const StyledSubmitButton = styled(LoadingButton)`
  border-radius: 150px !important;
`

export const SubmitButtonText = styled.span`
  font-size: 16px;
  /* font-weight: 500; */
`

export const LogoWrapper = styled.div(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  return `
    margin-bottom: ${isMobile ? '0' : '32px'};
  `
})

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #D0D0DB;
  margin-bottom: 12px;
`