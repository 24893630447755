import 'styled-components/macro';

import { VFC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';
import styled from 'styled-components';

import { login, resetAccountErrorCode } from 'src/store/user/actions';
import { I18n } from 'src/components/I18n';
import {
  emailValidation,
  passwordRequired,
  referralCodeRequired
} from 'src/components/shared/FormElements/utils';
import { userSelector } from 'src/store/user/selectors';
import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';
import { L10nLink, Link } from 'src/components/common/Link';
import { AuthStep, LoadingTypes } from 'src/constants';
import { PasswordField } from 'src/components/common/PasswordField';
import { appConfig } from 'src/modules/app-config';

import { AuthBlockProps, EAuth } from '..';
import { ContentForm } from '../ContentForm/ContentForm';
import { handleModal } from '../utils';

const validationSchema = yup.object().shape({
  email: emailValidation,
  password: passwordRequired,
  referralCode: referralCodeRequired,
  enableSubscriptions: yup.boolean().required(),
});

const CheckboxField = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Label = styled.label`
  cursor: pointer;
  :hover {
    color: rgb(0, 108, 228);
    transition: color 0.3 ease;
  }
`

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

export const SignUpSection: VFC<AuthBlockProps> = ({
  setActiveModal,
  onSubmit,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { errorCode, isAuthenticated, status } = useSelector(userSelector);
  const [customErrorCode, setCustomErrorCode] = useState<number>()

  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get('invitationCode');
  const cvexRef = urlParams.get('cvexRef');
  const cvexSubRef = urlParams.get('cvexSubRef');
  const cvexCampaign = urlParams.get('cvexCampaign') === 'true';
  const nordicCampaign = urlParams.get('nordicCampaign') === 'true';
  const byBitCampaign = urlParams.get('byBitCampaign') === 'true';
  const ethermailCampaign = urlParams.get('ethermailCampaign') === 'true';
  const seierCapitalCampaign = urlParams.get('seierCapitalCampaign') === 'true';
  const jindermanCampaign = urlParams.get('jindermanCampaign') === 'true';
  const apiBaseUrl = appConfig('REACT_APP_API_BASE_URL');

  useEffect(() => {
    if (isAuthenticated && onSubmit) onSubmit();
  }, [isAuthenticated]);

  useEffect(
    () => () => {
      dispatch(resetAccountErrorCode());
    },
    [dispatch]
  );

  const { handleSubmit, control, watch } = useForm<{
    email: string;
    password: string;
    referralCode: string;
    cvexRef: string;
    cvexSubRef: string;
    enableSubscriptions: boolean;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      enableSubscriptions: true,
      referralCode: referralCode || '',
      cvexRef: cvexRef || '',
      cvexSubRef: cvexSubRef || '',
    },
  });
  
  const enableSubscriptions = watch('enableSubscriptions');

  const intlFormatMessage = useIntlFormatMessage();

  return (
    <ContentForm
      {...rest}
      subscription={enableSubscriptions}
      loading={status === LoadingTypes.RUNNING}
      headerTextId="SIGN_UP_FORM.MODAL.HEADER_NAME"
      submitButtonI18nId="SIGN_UP_FORM.MODAL.BUTTON_NAME"
      alertCode={customErrorCode || errorCode}
      cvexCampaign={cvexCampaign}
      nordicCampaign={nordicCampaign}
      byBitCampaign={byBitCampaign}
      ethermailCampaign={ethermailCampaign}
      seierCapitalCampaign={seierCapitalCampaign}
      jindermanCampaign={jindermanCampaign}
      authStep={AuthStep.SIGN_IN}
      onSubmit={handleSubmit(async (values) => {
        const response = await fetch(`${apiBaseUrl}/customers/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
            referralCode: values.referralCode,
            enableSubscriptions: values.enableSubscriptions
          })
        })

        const parsedResponse = await response.json();

        if (parsedResponse.error && parsedResponse.errorCode) {
          setCustomErrorCode(parsedResponse.errorCode)
          
          return;
        }

        if (parsedResponse.isSuccessful) {
          dispatch(login({
            email: values.email,
            password: values.password,
          }));
        }
      })}
      navigateBlock={!cvexCampaign && !nordicCampaign && !seierCapitalCampaign && !jindermanCampaign ?
        <>
         <Typography variant="body4">
            <I18n id="SIGN_UP_FORM.MODAL.FOOTER.TEXT" />{' '}
            <Link
              to={`/${EAuth.SIGN_IN}`}
              onClick={handleModal(setActiveModal, EAuth.SIGN_IN)}
              color="secondary"
              variant="body4"
            >
              <I18n id="SIGN_IN_FORM.NAME" />
            </Link>
          </Typography>
            {byBitCampaign ? (
              <Typography variant="body4" textAlign="center">
                <I18n id="SIGN_IN_UP_FORM.MODAL.SUPPORT_TEXT" />
                {" "}
                <L10nLink
                  target="_blank"
                  color="secondary"
                  typography="body4"
                  to="/contact"
                >
                  <I18n id="SIGN_IN_UP_FORM.MODAL.SUPPORT_LINK" />
                </L10nLink>
              </Typography>
            ) : null}
          </>
        : null
      }
      termsBlock={
        <TermsContainer>
          <Typography variant="body4" textAlign="center">
            <I18n id="SIGN_IN_UP_FORM.MODAL.TERMS_TEXT" />
            {" "}
            <L10nLink
              target="_blank"
              color="secondary"
              typography="body4"
              to="/privacy-policy"
            >
              <I18n id="SIGN_IN_UP_FORM.MODAL.PRIVACY_POLICY" />
            </L10nLink>,
            <br/>
            <I18n id="SIGN_IN_UP_FORM.MODAL.AND" />
            {" "}
            <L10nLink
              target="_blank"
              color="secondary"
              typography="body4"
              to="/terms-and-conditions"
            >
              <I18n id="SIGN_IN_UP_FORM.MODAL.TERMS_AND_CONDITIONS" />
            </L10nLink>
          </Typography>

          {errorCode !== 0 && (
            <Controller
              name="enableSubscriptions"
              control={control}
              render={({ field }) => (
                <CheckboxField>
                  <Checkbox {...field} id="enableSubscriptions" defaultChecked />
                  <Label htmlFor="enableSubscriptions">
                    <I18n id="SIGN_UP_FORM.SUBSCRIPTION.LABEL" />
                  </Label>
                </CheckboxField>
              )}
            />
          )}
        </TermsContainer>
      }
    >
      {(errorCode !== 0 || byBitCampaign) && (
        <>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Invalid email address',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder={intlFormatMessage(
                  'SIGN_IN_FORM.EMAIL_FIELD.PLACEHOLDER'
                )}
                color="focusSecondary"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                label={
                  <>
                    <I18n id="SIGN_IN_FORM.EMAIL_FIELD.LABEL" /> *
                  </>
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                {...field}
                placeholder={intlFormatMessage(
                  'SIGN_IN_FORM.MODAL.PASSWORD.PLACEHOLDER'
                )}
                color="focusSecondary"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                label={
                  <>
                    <I18n id="SIGN_IN_FORM.MODAL.PASSWORD.LABEL" /> *
                  </>
                }
              />
            )}
          />
          {!cvexCampaign && !nordicCampaign && !byBitCampaign && !seierCapitalCampaign && !jindermanCampaign ? (
            <Controller
              name="referralCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  placeholder={intlFormatMessage(
                    'SIGN_UP_FORM.REFERRAL_CODE_FILED.PLACEHOLDER'
                  )}
                  color="focusSecondary"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error?.message}
                  label={
                    <>
                      <I18n id="SIGN_IN_FORM.MODAL.REFERRAL_CODE.LABEL" /> *
                    </>
                  }
                />
              )}
            />
          ) : null}
        </>
      )}
    </ContentForm>
  );
};
