const BybitIcon = () => (
  <svg width="73" height="14" viewBox="0 0 73 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_8889_27895)">
  <path d="M25.2139 10.3107V1.21973H27.0412V10.3107H25.2139Z" fill="#F7A600"/>
  <path d="M3.91842 13.0109H0.000976562V3.91992H3.76089C5.58823 3.91992 6.65297 4.91582 6.65297 6.47363C6.65297 7.48202 5.96915 8.13368 5.49584 8.35065C6.0608 8.60589 6.78398 9.18032 6.78398 10.3939C6.78398 12.0915 5.58823 13.0109 3.91842 13.0109ZM3.61625 5.50347H1.8283V7.59746H3.61625C4.39174 7.59746 4.82564 7.17604 4.82564 6.55012C4.82564 5.9249 4.39174 5.50347 3.61625 5.50347ZM3.73439 9.19354H1.8283V11.428H3.73439C4.56284 11.428 4.95666 10.9176 4.95666 10.3042C4.95666 9.69149 4.56211 9.19354 3.73439 9.19354Z" fill="#121214"/>
  <path d="M12.3582 9.28255V13.0109H10.5438V9.28255L7.73047 3.91992H9.71532L11.4639 7.58425L13.186 3.91992H15.1708L12.3582 9.28255Z" fill="#121214"/>
  <path d="M20.3501 13.0109H16.4326V3.91992H20.1925C22.0199 3.91992 23.0846 4.91582 23.0846 6.47363C23.0846 7.48202 22.4008 8.13368 21.9275 8.35065C22.4924 8.60589 23.2157 9.18032 23.2157 10.3939C23.2157 12.0915 22.0199 13.0109 20.3501 13.0109ZM20.0479 5.50347H18.2599V7.59746H20.0479C20.8234 7.59746 21.2573 7.17604 21.2573 6.55012C21.2573 5.9249 20.8234 5.50347 20.0479 5.50347ZM20.166 9.19354H18.2599V11.428H20.166C20.9945 11.428 21.3883 10.9176 21.3883 10.3042C21.3883 9.69149 20.9945 9.19354 20.166 9.19354Z" fill="#121214"/>
  <path d="M32.9308 5.50347V13.0116H31.1035V5.50347H28.6582V3.91992H35.3761V5.50347H32.9308Z" fill="#121214"/>
  </g>
  <path d="M47.0706 7.1531H45.1697C45.135 6.90718 45.0641 6.68874 44.9571 6.49778C44.85 6.30394 44.7126 6.13902 44.5448 6.00304C44.377 5.86706 44.1831 5.76291 43.9633 5.69058C43.7463 5.61824 43.5105 5.58208 43.2559 5.58208C42.7958 5.58208 42.3951 5.69636 42.0537 5.92493C41.7123 6.1506 41.4476 6.48043 41.2595 6.91441C41.0715 7.3455 40.9775 7.86917 40.9775 8.48543C40.9775 9.11905 41.0715 9.6514 41.2595 10.0825C41.4505 10.5136 41.7167 10.8391 42.0581 11.059C42.3995 11.2788 42.7944 11.3888 43.2428 11.3888C43.4946 11.3888 43.7275 11.3555 43.9416 11.289C44.1586 11.2224 44.351 11.1255 44.5188 10.9982C44.6866 10.868 44.8254 10.7103 44.9354 10.5252C45.0482 10.34 45.1263 10.1288 45.1697 9.89154L47.0706 9.90022C47.0214 10.3082 46.8984 10.7016 46.7017 11.0807C46.5079 11.4568 46.246 11.7938 45.9162 12.0918C45.5893 12.3869 45.1987 12.6213 44.7444 12.7949C44.2931 12.9656 43.7824 13.0509 43.2125 13.0509C42.4197 13.0509 41.7109 12.8716 41.0859 12.5128C40.4639 12.154 39.9721 11.6347 39.6104 10.9548C39.2516 10.2749 39.0723 9.45177 39.0723 8.48543C39.0723 7.5162 39.2545 6.69163 39.6191 6.01172C39.9836 5.33182 40.4784 4.81393 41.1033 4.45806C41.7282 4.0993 42.4313 3.91992 43.2125 3.91992C43.7275 3.91992 44.2048 3.99225 44.6446 4.13691C45.0873 4.28157 45.4793 4.49278 45.8207 4.77053C46.1621 5.04539 46.4399 5.38245 46.654 5.78171C46.871 6.18098 47.0098 6.63811 47.0706 7.1531Z" fill="#121214"/>
  <path d="M49.783 12.9294H47.7693L50.8376 4.04144H53.2592L56.3231 12.9294H54.3094L52.0831 6.07248H52.0137L49.783 12.9294ZM49.6571 9.43585H54.4136V10.9027H49.6571V9.43585Z" fill="#121214"/>
  <path d="M57.3929 12.9294V4.04144H60.8995C61.5707 4.04144 62.1436 4.16151 62.618 4.40164C63.0954 4.63889 63.4585 4.97595 63.7073 5.41283C63.9591 5.84681 64.0849 6.35746 64.0849 6.94479C64.0849 7.53501 63.9576 8.04277 63.703 8.46807C63.4484 8.89048 63.0795 9.21452 62.5963 9.44019C62.1161 9.66586 61.5345 9.7787 60.8517 9.7787H58.5039V8.26844H60.5479C60.9067 8.26844 61.2047 8.21925 61.442 8.12088C61.6792 8.02251 61.8557 7.87496 61.9714 7.67822C62.09 7.48148 62.1493 7.237 62.1493 6.94479C62.1493 6.64968 62.09 6.40086 61.9714 6.19834C61.8557 5.99581 61.6777 5.84247 61.4376 5.73831C61.2004 5.63126 60.9009 5.57774 60.5393 5.57774H59.272V12.9294H57.3929ZM62.1927 8.88469L64.4017 12.9294H62.3273L60.166 8.88469H62.1927Z" fill="#121214"/>
  <path d="M68.5636 12.9294H65.4129V4.04144H68.5897C69.4837 4.04144 70.2533 4.21937 70.8985 4.57524C71.5436 4.92821 72.0398 5.43597 72.387 6.09852C72.7371 6.76107 72.9121 7.55381 72.9121 8.47675C72.9121 9.40258 72.7371 10.1982 72.387 10.8637C72.0398 11.5291 71.5408 12.0398 70.8898 12.3956C70.2417 12.7515 69.4663 12.9294 68.5636 12.9294ZM67.2921 11.3193H68.4855C69.041 11.3193 69.5083 11.221 69.8873 11.0242C70.2692 10.8246 70.5556 10.5165 70.7466 10.0998C70.9404 9.68033 71.0373 9.1393 71.0373 8.47675C71.0373 7.81999 70.9404 7.28329 70.7466 6.86667C70.5556 6.45005 70.2706 6.14336 69.8916 5.94663C69.5126 5.74989 69.0453 5.65152 68.4898 5.65152H67.2921V11.3193Z" fill="#121214"/>
  <defs>
  <clipPath id="clip0_8889_27895">
  <rect width="35.3762" height="13.8252" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
  );
  
  export default BybitIcon;