import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled, { useTheme } from 'styled-components/macro';
import { parse } from 'query-string';
import Cookies from 'js-cookie';

import { getHotel, getHotelRooms } from 'src/store/hotel/actions';
import {
  cheapestPackagePriceSelector,
  hotelInfoSelector,
  hotelSelector,
} from 'src/store/hotel/selectors';
import { intlSelector } from 'src/store/intl/selectors';
import { ACCESS_TOKEN_COOKIE_NAME, LoadingTypes } from 'src/constants';
import { useFirstRender } from 'src/hooks/useFirstRender';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { media } from 'src/modules/mediaQuery';
import { DeviceContext, ScreenSize } from 'src/modules/DeviceProvider';
import MainLoader from 'src/components/MainLoader';
import { I18n } from 'src/components/I18n';
import MainContainer from 'src/components/common/MainContainer';
import { ErrorPage } from 'src/components/common/ErrorPage';
import { CustomDivider } from 'src/components/shared/CustomDivider';
import { breakPoints } from 'src/configs';
import { deprecatedParamsSelector } from 'src/store/deprecated/selectors';
import { SearchParamsState } from 'src/store/search/reducers';
import { FieldsName } from 'src/components/Panels/SearchPanel';
import { setDeprecatedParams } from 'src/store/deprecated/actions';
import { L10nLink } from 'src/components/common/Link';
import { LoyaltyButton } from 'src/components/common/loyalty-button';
import { appConfig } from 'src/modules/app-config';
import useConfig from 'src/hooks/swr/useConfig';

import { useSectionsNavigator } from './useSectionsNavigator';
import { StickyHeader } from './StickyHeader';
import { MobileSearchForm } from './HotelDetailsSearchForm/MobileSearchForm';
import { ChildAndExtraBedPolicy } from './HotelInfo/ChildAndExtraBedPolicyPanel';
import { HotelFacilities } from './HotelInfo/HotelFacilities';
import { AboutHotel } from './HotelInfo/AboutHotel';
import HotelReview from './HotelReview';
import HotelRooms, { NoResultsError } from './Rooms';
import GeneralInfo from './GeneralInfo';
import { SearchForm } from './HotelDetailsSearchForm/SearchForm';

const StyledCustomDivider = styled(CustomDivider)`
  margin: 35px 0;
  border-color: ${(p) => p.theme.custom.greyAlternativeColor};
`;

const HotelDetailsPageWrapper = styled.div`
  background: ${(p) => p.theme.custom.whiteMainColor};
  border-top: 2px solid ${(p) => p.theme.custom.greySecondaryColor};
  border-bottom: 2px solid ${(p) => p.theme.custom.greySecondaryColor};

  ${MainContainer} ${StyledCustomDivider}:nth-child(4) {
    margin-bottom: 25px;

    ${media.tabletDesktop`
      margin-top: 0;
    `}
  }
`;

const StyledContainer = styled(MainContainer)`
  padding-bottom: 50px;

  @media (max-width: ${breakPoints.md + 1}px) {
    padding-bottom: 25px;
  }
`;

const Availability = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: ${(p) => p.theme.custom.blackMainColor};
`;

const SearchFormWrapper = styled.div`
  margin-bottom: 63px;
`;

const LoyaltyButtonContainer = styled.div`
  margin-left: calc(50% - 123px);
  margin-top: 16px;
  max-width: 300px;
`;

const LoyaltyButtonMobileContainer = styled.div`
  margin-bottom: 16px;
`

const StyledLoyaltyButton = styled(LoyaltyButton)`
  max-width: 500px;
`

const defaultRooms = [
  {
    adults: 2,
    children: [],
  },
];

export const STATIC_FORM_VALUES: Omit<
  SearchParamsState,
  FieldsName.DESTINATION
> = {
  dates: [null, null],
  rooms: defaultRooms,
  businessTrip: false,
};

const HotelDetailsPage: FC<any> = (props) => {
  const { match, isStatic } = props;
  const hotelId = match?.params?.hotelId;

  const { searchId, es } = useSelector(deprecatedParamsSelector);

  const {
    status,
    detailsStatus,
    isFinished: isSearchFinished,
    error,
  } = useSelector(hotelSelector);
  const isLoading = status === LoadingTypes.RUNNING;
  const isDetailsLoading = detailsStatus === LoadingTypes.RUNNING;
  const info = useSelector(hotelInfoSelector);
  const cheapestPackagePrice = useSelector(cheapestPackagePriceSelector);
  const { currentLocale } = useSelector(intlSelector);
  const isFirstRender = useFirstRender();
  const dispatch = useDispatch();
  const [isSearchFormPortalOpen, setSearchFormPortalOpen] = useState(false);
  const { screenSize } = useContext(DeviceContext);
  const [bookBtnsLoading, setBookBtnsLoading] = useState(true);
  const apiBaseUrl = appConfig('REACT_APP_API_BASE_URL');
  const { data: config } = useConfig();

  const theme = useTheme();

  const isDesktop = useMatchMedia(theme.breakpoints.up('lg'));

  const { setSectionRef, scrollToSection, activeSectionId } =
    useSectionsNavigator({
      watchNodes: isDesktop && Boolean(cheapestPackagePrice),
    });

  useEffect(() => {
    dispatch(getHotel({ hotelId, silent: true, searchId }));
  }, [currentLocale, hotelId, searchId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isStatic && !isDetailsLoading && !error && info && info.tradable) {
      dispatch(setDeprecatedParams({ searchId }));

      dispatch(getHotelRooms({ hotelId, srId: searchId }));
    }
  }, [isStatic, detailsStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBookBtnsLoading(!isSearchFinished);

    const timedId = window.setTimeout(() => {
      setBookBtnsLoading(false);
    }, 14000);

    return () => window.clearInterval(timedId);
  }, [searchId, isSearchFinished]);

  const sendDataToServer = () => {
    const url = window.location.href;
    const token = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const data = {
      pageUrl: url,
      city: info!.city,
      hotel: info?.name,
      price: info?.price,
      token: token
    };

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    navigator.sendBeacon(`${apiBaseUrl}/customers/interest`, blob);
  };

  useEffect(() => {
    const handleUnload = () => {
      sendDataToServer();
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [info]);

  useEffect(() => {
      if (info?.name) {
        document.title = `${info?.name}`;
      }
  }, [info?.name]);

  const handleNavigateToSearchForm = useCallback(() => {
    if (screenSize !== ScreenSize.desktop) {
      setSearchFormPortalOpen(true);
    }
  }, [setSearchFormPortalOpen, screenSize]);

  if (isLoading || isFirstRender || (!info && isDetailsLoading)) {
    return <MainLoader />;
  }

  const redirectToHomePage = () => {
    dispatch(push('/'));
  };

  if (!info) {
    return (
      <ErrorPage
        header={<I18n id="HOTEL_DETAILS_PAGE.NO_RESULTS.HEADER" />}
        text={<I18n id="HOTEL_DETAILS_PAGE.NO_RESULTS.TEXT" />}
        buttonText={<I18n id="HOTEL_DETAILS_PAGE.NO_RESULTS.BTN" />}
        onBtnClick={redirectToHomePage}
      />
    );
  }

  const reviewId = info?.review?.id;
  const params = parse(props.location.search)
  const hasReview = Boolean(reviewId);

  if (params.discount) {
    info.discount = +params.discount
  }

  return (
    <HotelDetailsPageWrapper>
      {isDesktop && Boolean(cheapestPackagePrice) && (
        <StickyHeader
          activeId={activeSectionId}
          onActiveIdChange={scrollToSection}
          price={cheapestPackagePrice!}
          withReviews={hasReview}
        />
      )}
      <StyledContainer size="lg">
        {!isDesktop && (
          <MobileSearchForm
            isSearchFormPortalOpen={isSearchFormPortalOpen}
            setSearchFormPortalOpen={setSearchFormPortalOpen}
            isStatic={isStatic}
          />
        )}
        <GeneralInfo
          sectionRef={setSectionRef}
          data={info}
        />
        <StyledCustomDivider dashed={true} />
        {!isStatic && (
          <Availability>
            <I18n id="HOTEL_DETAILS_PAGE.TITLE" />
          </Availability>
        )}
        {isDesktop ? (
          <SearchFormWrapper>
            <SearchForm isStatic={isStatic} />
            {config?.featureToggles.ShowLoyaltyProgram ? (
              <LoyaltyButtonContainer>
                <LoyaltyButton variant="secondary" />
              </LoyaltyButtonContainer>
            ) : null}
          </SearchFormWrapper>
        ) : 
          config?.featureToggles.ShowLoyaltyProgram ? (
            <LoyaltyButtonMobileContainer>
              <StyledLoyaltyButton variant="secondary" />
            </LoyaltyButtonMobileContainer>
          ) : null
        }

        {!isStatic && info.tradable && (
          <>
            <HotelRooms
              sectionRef={setSectionRef}
              scrollToSection={scrollToSection}
              bookBtnsLoading={bookBtnsLoading}
              handleNavigateToSearchForm={handleNavigateToSearchForm}
              searchId={searchId!}
              es={es!}
            />
            <StyledCustomDivider dashed={true} />
          </>
        )}
        {!info.tradable && (
          <>
            <NoResultsError>
              <I18n
                id="HOTEL_DETAILS_PAGE.ROOMS.NOT_TRADABLE"
                values={{
                  a: (msg: string) => (
                    <L10nLink to="/search" color="rgb(4, 141, 59)">
                      {msg}
                    </L10nLink>
                  ),
                }}
              />
            </NoResultsError>
            <StyledCustomDivider dashed={true} />
          </>
        )}
        <AboutHotel
          sectionRef={setSectionRef}
          info={info}
          scrollToSection={scrollToSection}
        />
        <StyledCustomDivider dashed={true} />
        <HotelFacilities
          sectionRef={setSectionRef}
          hotelFacilities={info.facilityGroups}
        />
        {/* TODO: Check hotelPolicies later */}
        {Boolean(info.hotelPolicies?.length) && (
          <>
            <StyledCustomDivider dashed={true} />
            <ChildAndExtraBedPolicy hotelPolicies={info.hotelPolicies} />
          </>
        )}
        {hasReview && (
          <>
            <StyledCustomDivider dashed={true} />
            <HotelReview
              sectionRef={setSectionRef}
              hotelTrustYouId={reviewId!}
            />
          </>
        )}
      </StyledContainer>
    </HotelDetailsPageWrapper>
  );
};

export default HotelDetailsPage;
